/* SurveyJS Styling to fix style conflicts with tailwind */
/* Note that we do not want to include this in Tailwind layers as they should
only be used for SurveyJS and not as Tailwind theme classes*/
.sd-root-modern {
  --primary: theme(colors.primary.DEFAULT);
  --primary-foreground: theme(colors.neutral-light.DEFAULT);
  --background: theme(colors.neutral-light.DEFAULT);
  --background-dim: theme(colors.neutral-light-tint.DEFAULT);
  --background-dim-light: theme(colors.primary.100);
  --foreground: theme(colors.neutral-dark.DEFAULT);
  --foreground-light: theme(colors.neutral-dark.DEFAULT);
  --base-unit: theme(spacing.1);
  --font-family: theme(fontFamily.primary);
  --font-surveytitle-family: theme(fontFamily.title);
  --font-pagetitle-family: theme(fontFamily.title);
  --font-questiontitle-family: theme(fontFamily.title);

  & .sd-input {
    background-color: var(--background-dim-light) !important;
  }

  & .sd-input[type="password"] {
    width: 100% !important;
  }

  & .sd-radio__decorator {
    background: var(--background-dim-light) !important;
  }

  & .sd-dropdown__hint-prefix {
    display: none !important;
  }

  & .sd-dropdown {
    background: var(--background-dim-light) !important;

    & .sd-dropdown__value {
      background: var(--background-dim-light) !important;

      & .sv-string-viewer {
        background: var(--background-dim-light) !important;
      }

      & .sd-dropdown__filter-string-input {
        background: transparent !important;
      }
    }
  }

  & .sv-components-column {
    & .sv-action {
      background: var(--primary) !important;
    }
  }
  & .sv-popup__container {
    & .sv-popup__body-content {
      background: var(--background-dim-light) !important;
    }
  }
  & .sv-popup {
    & .sv-list {
      background: var(--background-dim-light) !important;
    }
    & .sv-list__item--selected {
      & .sv-list__item-body {
        background: var(--background-dim-light) !important;
      }
    }
  }
}

/* BUTTONS */
& .sd-btn.sd-btn--action.sd-navigation__complete-btn {
  background-color: theme(colors.primary.DEFAULT) !important;
  color: white;
}

& .sd-btn.sd-btn--action.sd-navigation__complete-btn:hover {
  background-color: theme(colors.primary.400) !important;
}

&
  .sd-action.sd-paneldynamic__btn.sd-action--negative.sd-paneldynamic__remove-btn {
  background-color: theme(colors.status.danger.DEFAULT) !important;
  color: white !important;
}

&
  .sd-action.sd-paneldynamic__btn.sd-action--negative.sd-paneldynamic__remove-btn:hover {
  background-color: theme(colors.status.danger.tint) !important;
  color: white !important;
}

&
  .sd-action.sd-paneldynamic__btn.sd-paneldynamic__add-btn.sd-paneldynamic__add-btn--list-mode {
  background-color: theme(colors.primary.DEFAULT) !important;
  color: white !important;
}

&
  .sd-action.sd-paneldynamic__btn.sd-paneldynamic__add-btn.sd-paneldynamic__add-btn--list-mode:hover {
  background-color: theme(colors.primary.400) !important;
  color: white !important;
}

/* To Avoid primary overriding the background color of buttons */
& .sv-action__content {
  background-color: white !important;
}

/* Multi Select item Boxes */
& .sd-input.sd-tagbox.sd-dropdown {
  min-height: 50px !important;
}

& .sv-tagbox__item {
  background-color: var(--background-dim-light) !important;
  & .sv-tagbox__item-text {
    color: black !important;
  }
}

& .sv-tagbox__item:hover {
  background-color: var(--background-dim-light) !important;
}

& .sd-tagbox-item_clean-button {
  padding: 10px !important;
  opacity: 0.2 !important;
  background: theme(colors.primary.450) !important;
}

& .sd-tagbox_clean-button.sd-dropdown_clean-button {
  padding: 0px !important;
}

&
  .sd-input.sd-dropdown[aria-expanded="false"]
  > div.sd-dropdown__value
  > input {
  --background-dim-light: "";
}
& .sd-radio--checked.sd-radio--disabled .sd-radio__decorator:after {
  background-color: theme(colors.primary.450) !important;
  opacity: 25% !important;
}

& .sd-radio--checked .sd-radio__decorator:after {
  background-color: theme(colors.primary.450) !important;
}

& .generatedRolePanelContent {
  margin-block: -20px;
}

&
  .sd-element--nested.sd-element--nested-with-borders.sd-element.sd-element--complex.sd-panel.sd-row__panel {
  border: none;
}

& .sd-paneldynamic hr {
  display: none;
}

/* prevent global tailwind css from prevent list from rendering */
& ul {
  list-style: disc;
}
& ol {
  list-style: decimal;
}
