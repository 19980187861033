/*TODO: Need to confirm if this is working or not. Details below:
These variables do not get translated into raw values, therefore something like postcss-color-converter
will not convert them. However, they are hsl which does support a bolt-on alpha component. If
Ionic is themeing this by just bolting on a slash and alpha, it should work fine and we can remove
the postcss-color-converter. If it is not and is instead MUST have an rgb, then we will need to have
these be raw values so the postcss-color-converter will convert them. We could use something like
postcss-custom-properties found in the postcss-plugins npm package/plugin, but we would need a way to 
have it only run in this file, not all of them, which we may be able to use postcss-use for. For now
moving forward due to time constraints. In addition, we don't use many Ionic components and thus these may
not be being used at all.*/
:root {
  --ion-color-primary-rgb: var(--ion-color-primary);
  --ion-color-primary-contrast-rgb: var(--ion-color-primary-contrast);
  --ion-color-secondary-rgb: var(--ion-color-secondary);
  --ion-color-secondary-contrast-rgb: var(--ion-color-secondary-contrast);
  --ion-color-tertiary-rgb: var(--ion-color-tertiary);
  --ion-color-tertiary-contrast-rgb: var(--ion-color-tertiary-contrast);
  --ion-color-success-rgb: var(--ion-color-success);
  --ion-color-success-contrast-rgb: var(--ion-color-success-contrast);
  --ion-color-warning-rgb: var(--ion-color-warning);
  --ion-color-warning-contrast-rgb: var(--ion-color-warning-contrast);
  --ion-color-danger-rgb: var(--ion-color-danger);
  --ion-color-danger-contrast-rgb: var(--ion-color-danger-contrast);
  --ion-color-dark-rgb: var(--ion-color-dark);
  --ion-color-dark-contrast-rgb: var(--ion-color-dark-contrast);
  --ion-color-medium-rgb: var(--ion-color-medium);
  --ion-color-medium-contrast-rgb: var(--ion-color-medium-contrast);
  --ion-color-light-rgb: var(--ion-color-light);
  --ion-color-light-contrast-rgb: var(--ion-color-light-contrast);
}
