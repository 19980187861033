/*ANCHOR: Color Scheme Base (We do not support dark-mode yet)*/
:root {
  color-scheme: light only;
}

/*SECTION: Base Utilities*/
@layer utilities {
  /*ANCHOR: Style Resets*/
  .all-initial {
    all: initial;
  }

  .all-inherit {
    all: inherit;
  }

  .all-revert {
    all: revert;
  }

  .all-unset {
    all: unset;
  }

  /* TODO: STYLING This should remove the arrows from the number field when added.
  It does not. However, the added margin does make it not so bad on Chrome.*/
  .appearance-textfield {
    appearance: textfield;
  }
  .appearance-textfield::-webkit-outer-spin-button,
  .appearance-textfield::-webkit-inner-spin-button {
    @apply ml-4.5;

    appearance: textfield;
  }
  /* Background Party Effect */
  .bg-party {
    background: var(--color-status-party-gradient);
    background-size: 400% 400%;
  }

  /* Text Party Effect */
  .text-party {
    background: var(--color-status-party-gradient);
    background-size: 400% 400%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  /* Border Party Effect */
  .border-party {
    border-image: var(--color-status-party-gradient) 1;
    border-width: 1px;
    border-style: solid;
  }
}
/* !SECTION */

/*SECTION: Base Styles*/
@layer base {
  /*ANCHOR: Base Elements*/
  * {
    @apply !ring-0 !ring-transparent focus:!outline-none focus:!ring-0 focus:!ring-transparent focus-visible:!ring-0 focus-visible:!ring-transparent focus-visible:!outline-none;
  }

  html {
    @apply w-full h-full ring-0 focus:ring-0 active:ring-0;
  }

  body {
    @apply w-full h-full font-primary m-0 p-0;
  }

  /*ANCHOR: Interactable*/
  a {
    @apply link-decorated;
  }

  /*ANCHOR: Typography*/
  p {
    @apply font-primary text-base leading-normal;
  }

  h1 {
    @apply font-title text-3xl leading-tight font-semibold;
  }

  h2 {
    @apply font-title text-2xl leading-snug font-semibold;
  }

  h3 {
    @apply font-title text-xl leading-relaxed font-normal;
  }

  h4 {
    @apply font-title text-lg leading-normal font-normal;
  }

  h5 {
    @apply font-title text-base leading-normal font-normal;
  }

  h6 {
    @apply font-title text-sm leading-relaxed font-normal;
  }

  /*ANCHOR: Scrollbars*/
  * {
    scrollbar-color: hsl(0, 0%, 85%) hsla(0, 0%, 0%, 0);
    scrollbar-width: thin;
  }

  *::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  *::-webkit-scrollbar-button {
    display: hidden;
  }

  *::-webkit-scrollbar-track {
    background: hsla(0, 0%, 0%, 0);
  }

  *::-webkit-scrollbar-track-piece {
    background: hsla(0, 0%, 0%, 0);
  }

  *::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 85%);
    border-radius: 8px;
  }

  /*ANCHOR: Inputs*/
  label {
    @apply text-sm text-neutral-mid;
  }

  /*TODO: "date" inputs will not style the default text as placeholder and have issues with
  styling in general. Using javascript, we should be able to style them, but it may not be possible
  with css directly.*/
  input[type="button"],
  input[type="color"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="file"],
  input[type="hidden"],
  input[type="image"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="radio"],
  input[type="range"],
  input[type="reset"],
  input[type="search"],
  input[type="submit"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  textarea {
    @apply input-mid;
  }

  input[type="checkbox"] {
    @apply input-mid cursor-pointer text-primary;
  }
}
/* !SECTION */
