/* Note that we do not want to include this in Tailwind layers as they should
only be used for ionic variables and not as Tailwind theme classes*/

/*ANCHOR: Root Variables*/
:root {
  --ion-font-family: theme(fontFamily.primary);

  --ion-color-primary: theme(colors.primary.DEFAULT);
  --ion-color-primary-contrast: theme(colors.neutral-light.DEFAULT);
  --ion-color-primary-shade: theme(colors.primary.650);
  --ion-color-primary-tint: theme(colors.primary-tint.DEFAULT);

  --ion-color-secondary: theme(colors.neutral-light.DEFAULT);
  --ion-color-secondary-contrast: theme(colors.neutral-dark.DEFAULT);
  --ion-color-secondary-shade: theme(colors.neutral-light.750);
  --ion-color-secondary-tint: theme(colors.neutral-light-tint.DEFAULT);

  --ion-color-tertiary: theme(colors.primary.300);
  --ion-color-tertiary-contrast: theme(colors.neutral-light.DEFAULT);
  --ion-color-tertiary-shade: theme(colors.primary.400);
  --ion-color-tertiary-tint: theme(colors.primary-tint.DEFAULT);

  --ion-color-success: theme(colors.status.success.DEFAULT);
  --ion-color-success-contrast: theme(colors.neutral-light.DEFAULT);
  --ion-color-success-shade: theme(colors.status.success.shade);
  --ion-color-success-tint: theme(colors.status.success.tint);

  --ion-color-warning: theme(colors.status.warning.DEFAULT);
  --ion-color-warning-contrast: theme(colors.neutral-light.DEFAULT);
  --ion-color-warning-shade: theme(colors.status.warning.shade);
  --ion-color-warning-tint: theme(colors.status.warning.tint);

  --ion-color-danger: theme(colors.status.danger.DEFAULT);
  --ion-color-danger-contrast: theme(colors.neutral-light.DEFAULT);
  --ion-color-danger-shade: theme(colors.status.danger.shade);
  --ion-color-danger-tint: theme(colors.status.danger.tint);

  --ion-color-dark: theme(colors.neutral-dark.DEFAULT);
  --ion-color-dark-contrast: theme(colors.neutral-light.DEFAULT);
  --ion-color-dark-shade: theme(colors.neutral-dark.400);
  --ion-color-dark-tint: theme(colors.neutral-dark.300);

  --ion-color-medium: theme(colors.neutral-mid.DEFAULT);
  --ion-color-medium-contrast: theme(colors.neutral-mid.100);
  --ion-color-medium-shade: theme(colors.neutral-mid.600);
  --ion-color-medium-tint: theme(colors.neutral-mid.400);

  --ion-color-light: theme(colors.neutral-light.DEFAULT);
  --ion-color-light-contrast: theme(colors.neutral-dark.DEFAULT);
  --ion-color-light-shade: theme(colors.neutral-light.700);
  --ion-color-light-tint: theme(colors.neutral-light-tint.DEFAULT);
}

/*Some of these are custom css classes (not prefixed with "ion"), but are specific to ionic only. 
Therefore, we don't want to include them in a Tailwind layer.*/

/*ANCHOR: Sidebar Menu */
/* TODO: Border between panes does not show */
ion-split-pane {
  --side-width: theme(width.30);
  --border: 1px solid theme(colors.neutral-mid.200);
}

ion-menu {
  /* TODO: This is producing a black bar on the right whenever width
    is played with. Right now the menu is far too large in larger screens. */
  /*Your IDE may complain about this and give you a red line. 
      Don't believe it. It transpiles just fine.*/
  --background: theme(colors.neutral-light-tint.DEFAULT);
}

ion-badge {
  --background: theme(colors.society.550);
}


ion-item::part(native) {
  --background: transparent;
  --background-hover: transparent;
  padding-right: theme(spacing.2);
}

ion-menu ion-content {
  --ion-item-background: theme(colors.neutral-light-tint.DEFAULT);
  --background: theme(colors.neutral-light-tint.DEFAULT);
}

ion-menu ion-list {
  background-color: theme(colors.neutral-light-tint.DEFAULT);
}

ion-menu ion-item {
  --border-radius: theme(borderRadius.lg);
  --padding-bottom: theme(spacing.1);
  --padding-top: theme(spacing.1);
  --padding-start: theme(spacing.2);
  --pading-end: theme(spacing.2);
  --inner-padding-top: theme(spacing.0);
  --inner-padding-bottom: theme(spacing.0);
  --inner-padding-start: theme(spacing.0);
  --inner-padding-end: theme(spacing.0);
  --min-height: theme(minHeight.fit);
  margin-top: theme(spacing.0);
  margin-bottom: theme(spacing.0);
  margin-left: theme(spacing.2);
  margin-right: theme(spacing.2);
  @apply text-neutral-mid;
}

ion-menu ion-icon, .ion-like-icon {
  --padding-bottom: theme(spacing.0);
  --padding-top: theme(spacing.0);
  --padding-start: theme(spacing.0);
  --pading-end: theme(spacing.0);
  --inner-padding-top: theme(spacing.0);
  --inner-padding-bottom: theme(spacing.0);
  --inner-padding-start: theme(spacing.0);
  --inner-padding-end: theme(spacing.0);
  @apply mt-0 mb-0 ml-0 mr-1.5 text-2xl;
}

.menu-button {
  @apply text-sm bg-transparent text-neutral-mid stroke-neutral-mid hover:text-society-550 hover:stroke-society-550 hover:bg-society-50;
}

.menu-button-close {
  @apply menu-button hidden;
}

.menu-action-bank {
  @apply w-full sticky bottom-0 bg-neutral-light-tint pb-3;
}

.menu-section-border {
  @apply border-t-2 border-sidebar-border w-5/6 self-center mx-auto pt-1;
}

/*NOTE: We should not include layout items in classes, but his particular case
is special in that we need to swap out very specific styles and layout based on
media queries, which is best managed inside CSS and not with JS*/
.menu-action-bank-button {
  @apply menu-button flex pl-2 pt-1 w-full;
}

ion-menu-toggle ion-badge {
  --background: theme(colors.society.100);
  --color: theme(colors.society.DEFAULT);
  @apply mt-0 mb-0 ml-3 mr-0;
}

@media (max-width: theme(screens.tablet.max)) {
  ion-menu {
    /* TODO: This is producing a black bar on the right whenever width
    is played with. Right now the menu is far too large in larger screens. */
    /*Your IDE may complain about this and give you a red line. 
      Don't believe it. It transpiles just fine.*/
    --background: theme(colors.neutral-light.DEFAULT);
  }

  ion-menu ion-content {
    --ion-item-background: theme(colors.neutral-light.DEFAULT);
    --background: theme(colors.neutral-light.DEFAULT);
  }

  ion-menu ion-list {
    background-color: theme(colors.neutral-light.DEFAULT);
  }

  .menu-button-close {
    @apply block;
  }

  .menu-action-bank {
    @apply w-fit relative flex flex-col mt-6 ml-2 mb-6 mr-0 border-t-0 bg-transparent;
  }

  .menu-action-bank-button {
    @apply menu-button h-fit w-fit pl-2 pt-0;
  }

  .menu-action-bank-icon {
    @apply hidden;
  }

  .menu-section-border {
    @apply hidden;
  }
}

/*ANCHOR: Modal*/
.modal-sheet-phone {
  --height: theme(height.full);
  --width: theme(width.full);
}

.modal-sheet-tablet {
  --height: theme(height.full);
  --width: theme(width.9/12);
  --max-width: theme(maxWidth.16xl);
}

.modal-sheet-base {
  --height: theme(height.full);
  --width: theme(width.90);
}

/*ANCHOR: Header*/
ion-toolbar {
  --background: theme(colors.neutral-light.DEFAULT);
  --color: theme(colors.neutral-mid.DEFAULT);
  /* --padding-start: theme(padding.2);
  --padding-end: theme(padding.2);
  --padding-top: theme(padding.1);
  --padding-bottom: theme(padding.1); */
}

/*ANCHOR: Toast*/
.toast {
  /*Your IDE may complain about this and give you a red line. 
    Don't believe it. It transpiles just fine.*/
  --max-width: theme(maxWidth.4/10);
  /*TODO: This should be theme(minWidth.6xl) but for some reason the calc
  inside this variable isn't working so this needed to be hardcoded.*/
  --min-width: 320px;
  @apply text-sm font-extrabold text-center;
}

.toast-info {
  --color: theme(colors.neutral-dark.DEFAULT);
  --background: theme(colors.primary.DEFAULT);
  @apply toast;
}

.toast-error {
  --color: theme(colors.neutral-light.DEFAULT);
  --background: theme(colors.status.danger.DEFAULT);
  @apply toast;
}

.toast-warning {
  --color: black;
  --background: theme(colors.primary.DEFAULT);
  @apply toast;
}

.toast-success {
  --color: theme(colors.neutral-light.DEFAULT);
  --background: theme(colors.status.success.DEFAULT);
  @apply toast;
}

/*ANCHOR: Menu*/
.side-menu {
  --background: theme(colors.neutral-light.DEFAULT);
  /*Your IDE may complain about this and give you a red line. 
    Don't believe it. It transpiles just fine.*/
  --max-width: theme(maxWidth.8/10)
    /*TODO: This should be theme(minWidth.6xl) but for some reason the calc
  inside this variable isn't working so this needed to be hardcoded.*/
    --min-width: 320px;
}

.ios-side-menu-wrapper {
  --padding-top: var(--ion-safe-area-top, 0);
}
