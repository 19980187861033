@layer utilities {
  /*ANCHOR: PageComponent*/
  .page-content-container {
    @apply overflow-auto;
  }

  .society-image {
    @apply rounded-lg border-1 border-society;
  }

  .society-image.loading {
    @apply bg-neutral-light-650;
    animation: pulse-animation 1.5s infinite ease-in-out;
  }

  @keyframes pulse-animation {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  /*ANCHOR: New App Table*/
  .pillartable-filters-selectmenu-button-container {
    @apply focus:border-0 focus:ring-0 focus-visible:outline-none;
  }

  .pillartable-filters-selectmenu-button-title {
    @apply text-sm font-medium text-neutral-mid-400 group-hover:text-neutral-mid;
  }

  .pillartable-filters-selectmenu-button-count {
    @apply rounded bg-neutral-light-600 px-0.25 py-0 text-xs font-semibold tabular-nums text-neutral-mid;
  }

  .pillartable-filters-selectmenu-button-icon {
    @apply stroke-neutral-mid-400 group-hover:stroke-neutral-mid-500;
  }

  .pillartable-filters-selectmenu-popover-container {
    @apply rounded-md bg-neutral-light p-2 shadow-2xl ring-1 ring-neutral-dark ring-opacity-5 focus:outline-none;
  }

  .pillartable-table-base {
    @apply border-separate border-spacing-0 rounded-t-lg border-transparent border bg-table-header-background shadow-xl;
  }

  /* TODO: STYLING Rounded bottom corners are not working on tables without pagination */
  .pillartable-table-base-roundbottom {
    @apply pillartable-table-base rounded-b-xl;
  }

  .pillartable-table-header {
    @apply text-left;
  }

  .pillartable-table-header-cell-general {
    @apply p-1.5 text-left text-xs font-semibold text-table-header-title border-b border-neutral-light-700;
  }

  .pillartable-table-header-cell-centered {
    @apply pillartable-table-header-cell-general px-0 text-center;
  }

  .pillartable-table-row-general {
    @apply border-b border-neutral-light-700 text-xs text-neutral-mid odd:bg-neutral-light even:bg-neutral-light-500;
  }

  .pillartable-table-row-interactable {
    @apply pillartable-table-row-general hover:bg-society-50 hover:cursor-pointer;
  }

  .pillartable-table-cell-general {
    @apply p-2;
  }

  .pillartable-table-cell-interactable {
    @apply pillartable-table-cell-general hover:underline;
  }

  .pillartable-pagination-container {
    @apply p-1.5 text-xs text-neutral-mid-700 rounded-b-xl border-l border-r border-b border-transparent bg-neutral-mid-100 shadow-xl;
  }

  .pillartable-pagination-display {
    @apply text-xs text-detailblock-dark-medium;
  }

  .pillartable-pagination-link-step-container {
    @apply link-undecorated p-0.25 text-sm text-neutral-mid-700 border border-neutral-mid-200 hover:bg-neutral-mid-200 hover:cursor-pointer first:rounded-l-md last:rounded-r-md;
  }

  .pillartable-pagination-link-step-svg {
    @apply stroke-neutral-mid-700 h-3 w-3;
  }

  .pillartable-pagination-link-page {
    @apply link-undecorated text-sm font-bold text-neutral-mid-700 p-0.25 border-t border-b border-neutral-mid-200 hover:cursor-pointer;
  }

  .pillartable-pagination-link-page--unselected {
    @apply pillartable-pagination-link-page hover:bg-neutral-mid-200;
  }

  /*TODO: Using the important (!) symbol here is not ideal.
  However, Tailwind seems to apply classes in an arbitrary order here, which breaks the expected class cascade.*/
  .pillartable-pagination-link-page--selected {
    @apply pillartable-pagination-link-page bg-primary !text-neutral-light !border-primary;
  }

  /*ANCHOR: New App Modal*/
  .appmodal-body-background {
    @apply bg-neutral-dark;
  }

  .appmodal-panel-container {
    @apply rounded bg-neutral-light p-2 text-left align-middle shadow-xl;
  }

  .appmodal-panel-title {
    @apply text-2xl font-semibold leading-relaxed text-neutral-mid-800;
  }

  /*ANCHOR: New App Drodown Menu*/
  .appdropdown-button-container {
    @apply input-light border-2;
  }

  .appdropdown-options-container {
    @apply rounded bg-neutral-light text-base shadow-lg ring-0 border border-neutral-mid-200;
  }

  .appdropdown-options-option {
    @apply select-none cursor-default text-sm pt-0.5 pb-0.5 hover:bg-neutral-light-600;
  }

  .appdropdown-options-option-unselected {
    @apply appdropdown-options-option text-neutral-mid;
  }

  .appdropdown-options-option-selected {
    @apply appdropdown-options-option bg-neutral-light-550 text-neutral-mid-900;
  }

  /*TODO: ui-disabled and ui-not-disabled should be usable without needing to swap classes out, but they are not working on either container or toggle*/
  /*ANCHOR: New App Switch*/

  .appswitch-container {
    @apply rounded-full cursor-pointer transition-colors duration-200 ease-in-out ring-0 focus:ring-0 ui-checked:bg-primary ui-not-checked:bg-neutral-mid-200;
  }

  .appswitch-container--disabled {
    @apply !bg-neutral-light-600;
  }

  /*TODO: ui-checked and ui-not-checked are also not working on the toggle along with ui-disabled and ui-not-disabled*/
  .appswitch-toggle-span {
    @apply pointer-events-none shadow transition duration-200 ease-in-out;
  }

  .appswitch-toggle-span-checked {
    @apply appswitch-toggle-span bg-neutral-light;
  }

  .appswitch-toggle-span-notchecked {
    @apply appswitch-toggle-span bg-neutral-mid;
  }

  .appswitch-toggle-span--disabled {
    @apply !bg-neutral-mid-250;
  }

  /*ANCHOR: New App Combobox*/
  .appcombo-button-container {
    @apply bg-neutral-light-550 stroke-neutral-mid-300 text-neutral-mid-300 hover:bg-neutral-light-700;
  }

  .appcombo-options-container {
    @apply rounded-md bg-neutral-light text-base text-neutral-mid-200 shadow-lg ring-0;
  }

  .appcombo-options-option {
    @apply cursor-default select-none text-sm text-neutral-mid p-0.5;
  }

  .appcombo-options-option-general {
    @apply appcombo-options-option ui-active:bg-primary ui-active:text-neutral-light;
  }

  .appcombo-options-option-add {
    @apply appcombo-options-option !text-primary !text-base hover:!text-primary-700;
  }

  /*ANCHOR: Conference Day Selector*/
  .dayselector-day-container {
    @apply py-1 px-2 text-neutral-mid hover:cursor-pointer;
  }

  .dayselector-day-container-unselected {
    @apply dayselector-day-container hover:bg-neutral-light-550;
  }

  .dayselector-day-container-selected {
    @apply dayselector-day-container bg-society-50;
  }

  .dayselector-day-weekday {
    @apply text-base tablet:text-xs;
  }

  .dayselector-day-weekday-selected {
    @apply dayselector-day-weekday text-society-600 font-bold;
  }

  .dayselector-day-weekday-unselected {
    @apply dayselector-day-weekday text-neutral-mid font-medium;
  }

  .dayselector-day-daynum {
    @apply text-2xl font-semibold tablet:text-xl text-center;
  }

  .dayselector-day-daynum-selected {
    @apply dayselector-day-daynum text-society-600;
  }

  .dayselector-day-daynum-unselected {
    @apply dayselector-day-daynum text-neutral-mid-800;
  }

  .dayselector-table-filter {
    @apply !rounded !border-neutral-mid-200 !text-neutral-mid-400;
  }

  /*ANCHOR: App Filters*/
  .appfilter-modal-container {
    @apply container-light text-neutral-mid-900;
  }

  .appfilter-modal-dropdown-container {
    @apply border-b border-neutral-mid text-base text-neutral-mid-800 pb-2 last:border-b-0;
  }

  /*ANCHOR: Favorite Event*/
  .favevent-icon {
    @apply hover:cursor-pointer;
  }

  .favevent-icon-favorited {
    @apply icon-favorited fill-status-flagged;
  }

  .favevent-icon-unfavorited {
    @apply favevent-icon icon-unfavorited fill-transparent;
  }

  /*ANCHOR: Schedule Event*/
  .schevent-container {
    @apply hover:cursor-pointer;
  }

  /*ANCHOR: Event Meta*/
  .eventmeta-icon-svg {
    @apply stroke-neutral-mid-400;
  }

  /*ANCHOR: Event Details*/
  .eventdetails-document-container {
    @apply rounded-md border border-solid border-neutral-mid-200 px-1.5 py-1 text-neutral-mid-600 text-xs font-semibold hover:bg-neutral-light-550 hover:cursor-pointer;
  }

  /*ANCHOR: Speaker Tile*/
  .speakertile-container {
    @apply rounded-md border border-solid border-neutral-mid-100 p-0.75;
  }

  /*ANCHOR: Profile Avatar*/
  .profile-avatar-container {
    @apply flex justify-center items-center shadow-sm rounded-full object-cover bg-society-550 text-xl text-neutral-light-500 font-semibold;
  }

  .profile-avatar-container.loading {
    @apply bg-neutral-light-650;
    animation: pulse-animation 1.5s infinite ease-in-out;
  }

  @keyframes pulse-animation {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  /*ANCHOR: Badge Pill*/
  .badgepill-container {
    @apply rounded-2xl text-sm font-semibold;
  }

  /*ANCHOR: Popover Menu Component*/
  .popovermenu-container {
    @apply container-light border border-neutral-mid-200 shadow-lg ring-0 ring-transparent rounded-md focus:ring-0 focus-visible:ring-0;
  }

  .popovermenu-item {
    @apply text-subtle whitespace-nowrap border-b border-neutral-mid-200 last:border-b-0 hover:text-primary hover:stroke-primary hover:cursor-pointer;
  }

  /*ANCHOR: Tabs*/
  .tabs-container {
    @apply bg-neutral-light-550 rounded-t border-t border-r border-l border-neutral-light-550 shadow-xl;
  }

  .tabs-tab {
    @apply rounded-t-sm p-1 text-base text-subheader text-center hover:bg-neutral-light-650 hover:cursor-pointer;
  }

  .tabs-tab-selected {
    @apply tabs-tab bg-neutral-light;
  }

  .tabs-tab-unselected {
    @apply tabs-tab;
  }

  .tabs-content-container-outer {
    @apply bg-neutral-light-tint border border-neutral-light-550 rounded-b shadow-xl p-0.75;
  }

  .tabs-content-container-inner {
    @apply bg-neutral-light-tint rounded-lg p-4 phone:px-1;
  }

  .overview-profile-triple-column {
    @apply flex-grow  w-1/3 phone:w-full mb-2;
  }

  .overview-profile-double-column {
    @apply flex-grow w-1/2 phone:w-full mb-2;
  }

  .sidebar-menu-selector {
    @apply rounded-lg text-start p-1 hover:bg-society-50 pl-2 py-1;
  }

  .sidebar-menu-svg {
    @apply text-neutral-mid-500 group-hover:text-society-550;
  }

  .sidebar-menu-title {
    @apply text-sm text-neutral-mid  group-hover:text-society-550;
  }

  .speakers-container {
    @apply scroll-smooth transition-transform space-x-2 overflow-hidden;
  }

  .chevron-slider-button {
    @apply px-0 py-0 border-0 bg-neutral-mid-300 rounded-full disabled:opacity-0 hover:bg-neutral-mid-200 disabled:hover:opacity-0;
  }

  .directory-responsive-avatar {
    @apply h-35 phone:h-20;
  }

  .conference-reg-details-link {
    @apply block text-sm font-medium text-status-general no-underline hover:text-status-general-tint;
  }

  .conference-reg-details-table {
    @apply flex flex-col w-full;
  }

  .conference-reg-details-table .header-row .cell {
    @apply pt-2 justify-start flex-col gap-y-1 font-semibold rounded-t-lg;
  }
  .conference-reg-details-table .last-row .cell {
    @apply rounded-b-lg;
  }

  .conference-reg-details-table .row {
    @apply flex w-full gap-x-1;
  }

  .conference-reg-details-table .cell {
    @apply p-0.5 flex flex-1 items-center justify-center text-center first:flex-1;
  }

  .conference-reg-details-table .highlight {
    @apply bg-neutral-light;
  }

  .conference-reg-details-table .inactive {
    @apply bg-neutral-light-600;
  }
}
